import { createEvent, createStore } from 'effector';

import { Profile } from '../types/profile';

export type ProfileStore = Profile | null;

export const updateProfile = createEvent<ProfileStore>();

export const resetProfile = createEvent();

export const $profile = createStore<ProfileStore>(null)
  .on(updateProfile, (_, payload) => payload)
  .reset(resetProfile);
