import toast from 'react-hot-toast';

import { CustomIcon } from '../components/custom-icon/custom-icon';
import { ALERT_TYPES } from '../constants/alert-types';

interface ShowAlertParams {
  alertTitle?: string;
  message?: string;
  type?: 'error' | 'success' | 'warning' | 'info';
}

export const showAlert = ({ alertTitle, message = '', type = 'error' }: ShowAlertParams) => {
  const { icon, bgColor, borderColor, iconColor, title } = ALERT_TYPES[type];

  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } flex w-full max-w-xs rounded-xl border-2 bg-white px-3 py-3 shadow-lg`}
      style={{
        backgroundColor: bgColor,
        borderColor: borderColor,
      }}
    >
      <div className='flex flex-1'>
        <div
          className='flex h-10 w-10 items-center justify-center rounded-full shadow-lg'
          style={{
            backgroundColor: iconColor,
            boxShadow: `0 10px 15px -3px ${iconColor}80, 0 4px 6px -4px ${iconColor}80`,
          }}
        >
          <CustomIcon className='h-6 w-6 text-white' name={icon} />
        </div>

        <div className='flex-1 px-4'>
          <p className='text-base font-medium'>{alertTitle || title}</p>
          <p className='text-gray-500 text-xs'>{message || ''}</p>
        </div>
      </div>

      <button
        className='mt-1 flex h-8 w-8 items-center justify-center rounded-xl bg-white text-dark'
        onClick={() => toast.dismiss(t.id)}
      >
        <CustomIcon className='h-3 w-3' name='rr-cross' />
      </button>
    </div>
  ));
};
