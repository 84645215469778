import { Link } from 'react-router-dom';

import { CustomIcon } from '../../../custom-icon/custom-icon';
import { BreadcrumbLinkProps } from '../../custom-breadrumbs';

interface CustomBreadcrumbLinkProps extends BreadcrumbLinkProps {
  index: number;
}

export const CustomBreadcrumbLink = ({ index, title, path }: CustomBreadcrumbLinkProps) => {
  if (!title) return null;

  return (
    <li className='inline-flex items-center text-text '>
      {!!index && <CustomIcon className='mx-1.5 h-2.5 w-2.5 opacity-50' name='sr-angle-left' />}

      {path ? (
        <Link
          className='whitespace-nowrap text-text underline underline-offset-2 transition hover:!text-primary'
          to={path}
        >
          <span>{title}</span>
        </Link>
      ) : (
        <span className='whitespace-nowrap opacity-60'>{title || ''}</span>
      )}
    </li>
  );
};
