import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import cn from 'classnames';

import { CustomBreadcrumbs, CustomBreadcrumbsProps } from '../custom-breadrumbs/custom-breadrumbs';

interface PageTitleProps extends PropsWithChildren<Partial<CustomBreadcrumbsProps> & HTMLAttributes<HTMLDivElement>> {
  buttons?: ReactNode;
  classNameWrap?: HTMLAttributes<HTMLDivElement>['className'];
  contentClassName?: HTMLAttributes<HTMLDivElement>['className'];
}

export const PageHeader = ({
  children,
  className,
  classNameWrap,
  breadcrumbs,
  contentClassName,
  buttons,
  ...props
}: PageTitleProps) => (
  <div className={cn('flex flex-wrap items-end justify-between gap-4', contentClassName)}>
    <div>
      {breadcrumbs?.length && <CustomBreadcrumbs breadcrumbs={breadcrumbs} breadcrumbsClassNameWrap={classNameWrap} />}

      <div className={cn('text-xl font-medium m595:text-3xl', className)} {...props}>
        {children}
      </div>
    </div>

    <div className='flex flex-wrap gap-2'>{buttons}</div>
  </div>
);
