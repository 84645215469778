import { HTMLAttributes } from 'react';

import cn from 'classnames';

import { CustomBreadcrumbLink } from './elems/breadcrumb-link/custom-breadcrumb-link';

import 'react-loading-skeleton/dist/skeleton.css';

export interface CustomBreadcrumbsProps extends HTMLAttributes<HTMLDivElement> {
  breadcrumbs: BreadcrumbLinkProps[];
  breadcrumbsClassNameWrap?: HTMLAttributes<HTMLUListElement>['className'];
  loading?: boolean;
}

export interface BreadcrumbLinkProps {
  title: string;
  path?: string;
}

export const CustomBreadcrumbs = ({ breadcrumbs, className, breadcrumbsClassNameWrap }: CustomBreadcrumbsProps) => {
  const linkList = [{ path: '/', title: 'Главная' }, ...breadcrumbs];

  return (
    <ul
      className={cn(
        'm-0 inline-flex max-w-[calc(100vw-32px)] flex-wrap overflow-x-auto !pl-0 text-[13px] font-medium',
        className,
        breadcrumbsClassNameWrap
      )}
    >
      {linkList.map((link, index) => (
        <CustomBreadcrumbLink key={index} {...link} index={index} />
      ))}
    </ul>
  );
};
