import cn from 'classnames';
import { Link } from 'react-router-dom';

import { NOTIFICATION_LINKS } from '../../../../constants/notification-links';
import { AvatarIcon } from '../../../avatar-icon/avatar-icon';
import { CustomBadge } from '../../../custom-badge/custom-badge';
import { CustomIcon } from '../../../custom-icon/custom-icon';
import { PermissionWrap } from '../../../permission-wrap/permission-wrap';
import { useAppHeader } from '../../use-app-header';

export const AppHeaderButtons = () => {
  const {
    profile,
    DROPDOWN_CLASSNAME,
    handleDropdownToggle,
    profileRef,
    notificationListRef,
    dropdownListRef,
    notificationCount,
    location,
    lastNotificationsQueryData,
    checkNotifications,
    handleLogout,
  } = useAppHeader();

  return (
    <>
      <div ref={notificationListRef} className='relative flex w-14 items-center justify-center'>
        <div
          className='relative flex h-full w-full cursor-pointer items-center justify-center hover:bg-bg'
          onClick={async () => {
            handleDropdownToggle(0);
            await checkNotifications();
          }}
        >
          <CustomIcon className='h-5 w-5' name='rr-bell' />
          {!!notificationCount && <CustomBadge className='absolute top-2.5 right-2.5'>{notificationCount}</CustomBadge>}
        </div>

        <div
          ref={dropdownListRef.current[0]}
          className={cn(DROPDOWN_CLASSNAME, '-left-11 z-[8000] max-h-[480px] w-72 overflow-y-auto m595:-left-28')}
        >
          {lastNotificationsQueryData?.data?.data?.notifications.length ? (
            lastNotificationsQueryData?.data?.data?.notifications.map(({ id, text, adminLink }) => (
              <Link
                key={id}
                className='block p-3 text-xs transition hover:bg-bg hover:text-gray'
                to={
                  adminLink?.id ? `/${NOTIFICATION_LINKS[adminLink?.url]}/${adminLink?.id}` : window.location.pathname
                }
              >
                {text}
              </Link>
            ))
          ) : (
            <div className='py-2 text-center text-[13px] font-medium'>Нет новых уведомлений</div>
          )}

          <Link
            className='block flex w-full items-center justify-center gap-x-1 py-2 text-center text-xs font-medium hover:text-gray'
            to='/notifications'
          >
            Все уведомления
            <CustomIcon className=' h-3 w-3' name='sr-angle-right' />
          </Link>
        </div>
      </div>

      <div ref={profileRef} className='relative'>
        <div
          className='flex h-full cursor-pointer items-center gap-2.5 px-3 text-text hover:bg-bg'
          onClick={() => handleDropdownToggle(1)}
        >
          {profile?.avatar ? (
            <img
              alt={profile?.fullUserName}
              className='h-10 w-10 rounded-full bg-bg object-cover'
              src={profile?.avatar}
            />
          ) : (
            <AvatarIcon />
          )}

          <div className='text-xs'>
            <p>
              {profile?.last_name || ''} {profile?.name || ''}
            </p>

            <p className='text-gray'>{profile?.email || ''}</p>
          </div>

          <CustomIcon className='h-2.5 w-2.5' name='sr-angle-down' />
        </div>

        <div ref={dropdownListRef.current[1]} className={cn(DROPDOWN_CLASSNAME, 'w-56 font-medium text-[#7A7F85]')}>
          <div className='flex h-full items-center gap-2.5 break-all px-3 py-4 text-text'>
            {/*{profile?.avatar ? (*/}
            {/*  <img*/}
            {/*    alt={profile?.fullUserName}*/}
            {/*    className='h-10 w-10 rounded-full bg-bg object-cover'*/}
            {/*    src={profile?.avatar}*/}
            {/*  />*/}
            {/*) : (*/}
            {/*  <AvatarIcon />*/}
            {/*)}*/}

            <div className='text-xs'>
              <p>ID: {profile?.id || ''}</p>
              <p>
                {profile?.last_name || ''} {profile?.name || ''} {profile?.middle_name || ''}
              </p>

              <p className='text-gray '>{profile?.email || ''}</p>
            </div>
          </div>
          <PermissionWrap grants={['profile', 'view']}>
            <Link className='flex items-center gap-2.5 py-3 px-4 hover:bg-bg/40' to={`/profile/${profile?.id}`}>
              <CustomIcon className='h-4 w-4 text-gray' name='sr-user' />
              Профиль
            </Link>
          </PermissionWrap>

          <PermissionWrap grants={['telegram', 'index']}>
            <Link className='flex items-center gap-2.5 py-3 px-4 hover:bg-bg/40' to='/telegram'>
              <CustomIcon className='h-4 w-4' name='telegram' />
              Telegram
            </Link>
          </PermissionWrap>

          <button
            className='flex w-full items-center gap-2.5 py-3 px-4 text-left hover:bg-bg/40'
            onClick={handleLogout}
          >
            <CustomIcon className='h-4 w-4' name='br-power' />
            Выйти
          </button>
        </div>
      </div>
    </>
  );
};
