import cn from 'classnames';

import { useAuthCheck } from './hooks/auth/use-auth-check';
import { AuthStack } from './routes/auth-stack/auth-stack';
import { MainStack } from './routes/main-stack/main-stack';
import { useApp } from './use-app';

import logo from './static/images/logo-ad.png';

export const App = () => {
  const { isLoading, authToken } = useAuthCheck();

  useApp();

  if (isLoading) {
    return (
      <div className='m-auto flex h-screen w-screen items-center justify-center'>
        <div className='pointer-events-none relative flex select-none items-center justify-center'>
          <img alt='99 Баллов' className={cn('max-w-[120px]')} src={logo} />

          <div className='flex flex-col justify-center pt-2'>
            <span className='text-[17px] font-medium leading-none text-red'>Админ Панель</span>
            <span className='text-[36px] font-bold leading-none tracking-wide'>Баллов</span>
          </div>
        </div>
      </div>
    );
  }

  if (!authToken) {
    return <AuthStack />;
  }

  return <MainStack />;
};
