import { queryClient } from '../index';
import { ApiPaginationResponseNotification, ApiResponse } from '../types/api';
import { CourseSelectProps } from '../types/course';
import { ExamProps } from '../types/exams';
import { BlockedList, CompletedLesson } from '../types/profile';
import { RoleProps } from '../types/role-props';
import { StatusProps } from '../types/status';
import { SubjectProps } from '../types/subjects';
import { TagProps } from '../types/tag-props';
import { NotificationsList } from '../types/user-info/user';

export const useQueriesData = () => ({
  accessListQueryData: queryClient.getQueryData(['accessList']) as Record<string, Record<string, boolean>>,
  blockedListQueryData: queryClient.getQueryData(['blockedList']) as ApiResponse<BlockedList[]>,
  completedLessonListQueryData: queryClient.getQueryData(['completedLesson']) as ApiResponse<CompletedLesson[]>,
  courseListQueryData: queryClient.getQueryData(['courseList']) as ApiResponse<{ results: any[] }>,
  courseTypeListQueryData: queryClient.getQueryData(['courseTypeList']) as ApiResponse<CourseSelectProps[]>,
  examTypeListQueryData: queryClient.getQueryData(['examTypeList']) as ApiResponse<ExamProps[]>,
  inactiveCourseListQueryData: queryClient.getQueryData(['inactiveCourseList']) as ApiResponse<CourseSelectProps[]>,
  roleListQueryData: queryClient.getQueryData(['roleList']) as ApiResponse<RoleProps[]>,
  statusListQueryData: queryClient.getQueryData(['statusList']) as ApiResponse<StatusProps[]>,
  subjectListQueryData: queryClient.getQueryData(['subjectList']) as ApiResponse<SubjectProps[]>,
  tagListQueryData: queryClient.getQueryData(['tagList']) as ApiResponse<TagProps[]>,
  baseUrlQueryData: queryClient.getQueryData(['base-url']) as ApiResponse<string>,
  notificationCountQueryData: queryClient.getQueryData([
    'notification-count',
  ]) as ApiPaginationResponseNotification<NotificationsList>,
});
