export const ALERT_TYPES = {
  error: {
    bgColor: '#fbeeeb',
    borderColor: '#eed0c6',
    icon: 'sr-error',
    iconColor: '#fc5758',
    title: 'Ошибка',
  },
  info: {
    bgColor: '#e7effa',
    borderColor: '#b4ccee',
    icon: 'sr-info',
    iconColor: '#3086eb',
    title: 'Инфо',
  },
  success: {
    bgColor: '#f1f9f5',
    borderColor: '#cee8d2',
    icon: 'sr-success',
    iconColor: '#50dc6c',
    title: 'Успешно',
  },
  warning: {
    bgColor: '#fff9eb',
    borderColor: '#f4e0b9',
    icon: 'sr-warning',
    iconColor: '#ffc122',
    title: 'Предупреждение',
  },
};
