import { CustomIcon } from '../custom-icon/custom-icon';
import { AppHeaderButtons } from './elems/app-header-buttons/app-header-buttons';

interface AppHeaderProps {
  toggleMenu: () => void;
}

export const AppHeader = ({ toggleMenu }: AppHeaderProps) => (
  <div className='flex h-14 justify-between border-b border-[#e7e8ec] bg-white pl-4 xl:pl-6'>
    <div className='flex cursor-pointer items-center justify-center text-white' onClick={toggleMenu}>
      <CustomIcon className='h-5 w-5 text-text transition hover:text-primary' name='burger-menu' />
    </div>

    <div className='flex'>
      <AppHeaderButtons />
    </div>
  </div>
);
