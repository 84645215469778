import { createEffect, createEvent, createStore, sample } from 'effector';

import { queryClient } from '../index';
import { resetProfile } from './profile';

export interface AuthStore {
  authToken: string | null;
}

export const login = createEvent<string>();
export const logout = createEvent();

export const $auth = createStore<AuthStore>({
  authToken: null,
})
  .on(login, (state, token) => ({ ...state, authToken: token }))
  .reset(logout);

const setAuthLSFx = createEffect((token: string) => {
  localStorage.setItem('authToken', token);
});

const clearLSFx = createEffect(() => {
  localStorage.clear();
  sessionStorage.clear();
  queryClient.clear();
});

sample({
  source: logout,
  target: [resetProfile, clearLSFx],
});

sample({
  source: login,
  target: setAuthLSFx,
});
