import { useEffect } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

import { $tableApi } from './store/table';
import { $themeSearchListApi, $userSearchListApi } from './store/user-search-list';

export const useApp = () => {
  const location = useLocation();
  const [urlParams] = useSearchParams();

  // useEffect(() => {
  //   const body = window.document.querySelector('body');
  //
  //   if (modalVisible) {
  //     body?.classList.add('overflow-hidden');
  //     body?.classList.add('fixed');
  //   } else {
  //     body?.classList.remove('fixed');
  //     body?.classList.remove('overflow-hidden');
  //   }
  // }, [modalVisible]);

  useEffect(() => {
    const mathTypeDialog = window.document.querySelectorAll('.wrs_modal_dialogContainer');

    if (mathTypeDialog) for (const el of mathTypeDialog) el.classList.add('hidden');
    $userSearchListApi.reset();
    $themeSearchListApi.reset();

    const permissionReset = !urlParams.toString().length;

    permissionReset && $tableApi.reset();

    window.scrollTo({ top: 0 });
  }, [location.pathname]);
};
