import { useQueriesData } from './use-queries-data';

export const usePermission = (grants: string[]) => {
  const { accessListQueryData } = useQueriesData();

  if (!grants.length) {
    throw new Error('usePermission() must have at least 1 parameter');
  }

  if (!grants[1]) {
    return accessListQueryData?.[grants[0]];
  }

  return accessListQueryData?.[grants[0]][grants[1]];
};
