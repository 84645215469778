import { createApi, createStore } from 'effector';

export type TableStore<T = any> = Record<string, TableStoreValue<T>>;
export type TableStoreValue<T = any> = Record<string, T>;

export const $table = createStore<TableStore>({});

export const $tableApi = createApi($table, {
  update: (s, { name, value }: { name: string; value: TableStoreValue }) => ({
    ...s,
    [name]: { ...s[name], ...value },
  }),
  clear: (s, name: string) => ({
    ...s,
    [name]: {},
  }),
  reset: () => ({}),
});
