import { createUrlQuery } from '../helpers/create-url-query';
import { PrepareSelectDataProps } from '../helpers/select';
import { RevenueDashboard } from '../pages/main/desktop-page/use-desktop-page';
import { ApiFetchCommonParams, ApiResponse } from '../types/api';
import { apiRequest } from './index';
export interface DesktopModel {
  average_live: null | number;
  average_webinar: null | number;
  conversion_percentage: null | number;
  count_current_pupils: number;
  count_current_unique_pupils: number;
  count_next_pupils: number;
  count_next_unique_pupils: number;
  percent_accept_user_homework: null | number;
  percentage_prepayments: null | number;
  revenue: null | string;
  revenue_all_pm_filter: null | string;
  revenue_filter: null | string;
  total_revenue: null | string;
}
export interface Desktop {
  model: DesktopModel;
  personalManagerModel: DesktopModel;
}

export const fetchDesktop = async (
  props: ApiFetchCommonParams & { personal_manager_id: number }
): Promise<ApiResponse<Desktop>> => apiRequest({ url: `/site/index${createUrlQuery(props)}` });

export const fetchDesktopPmList = async (): Promise<ApiResponse<PrepareSelectDataProps[]>> =>
  apiRequest({ url: '/site/get-user-list' });

export const fetchBaseUrl = async (): Promise<ApiResponse<string>> => apiRequest({ url: '/site/base-url' });

export const fetchRevenueDashboard = async (props: {
  dateFrom: string;
  dateTo: string;
  personal_manager_id: number | undefined;
}): Promise<ApiResponse<RevenueDashboard>> =>
  apiRequest({ url: `/site/revenue-filter-dashboard${createUrlQuery(props)}` });
