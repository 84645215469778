import { useEffect, useState } from 'react';

import { useStoreMap } from 'effector-react';

import { apiRequest } from '../../api';
import { $auth, login } from '../../store/auth';
import { updateProfile } from '../../store/profile';
import { ApiResponse } from '../../types/api';
import { Profile } from '../../types/profile';

export const useAuthCheck = () => {
  const [isLoading, setIsLoading] = useState(true);
  const authToken = useStoreMap($auth, (s) => s.authToken);

  useEffect(() => {
    (async () => {
      const authToken = localStorage.getItem('authToken');

      if (authToken) {
        const res: ApiResponse<Profile> = await apiRequest({
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          url: '/user/check-token',
        });

        if (!res.error) {
          updateProfile(res.data);
          login(authToken);
        }
      }

      setIsLoading(false);
    })();
  }, []);

  return { authToken, isLoading };
};
