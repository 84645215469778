import { HTMLAttributes, PropsWithChildren } from 'react';

import cn from 'classnames';

type CustomBadgeProps = PropsWithChildren<HTMLAttributes<HTMLSpanElement>>;

export const CustomBadge = ({ children, className, ...props }: CustomBadgeProps) => (
  <span
    className={cn(
      'inline-flex items-center justify-center rounded-xl bg-red py-1 px-1.5 text-[9px] leading-none text-white',
      className
    )}
    {...props}
  >
    {children}
  </span>
);
