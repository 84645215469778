import { JobBankDailogMultiTaskForm } from '../components/modals/job-bank-multi-task-modal/use-job-bank-multi-task-modal';
import { JobBankForm } from '../components/modals/job-bank-text-modal/use-job-bank-text-dialog';
import { JobBankAskTableForm } from '../components/tables/job-bank-ask-table/use-job-bank-ask-table';
import { JobBankErrorTableForm } from '../components/tables/job-bank-error-messages-table/use-job-bank-error-messages-table';
import { JobBankGroupTableForm } from '../components/tables/job-bank-group-table/use-job-bank-group-table';
import { JobBankMultipleForm } from '../components/tables/job-bank-multiple-table/use-job-bank-multiple';
import { JobBankThemeTextViewList } from '../components/tables/job-bank-text-table/use-job-bank-text-table';
import { JobBankThemeTableForm } from '../components/tables/job-bank-theme-table/use-job-bank-theme-table';
import { JobBankThemeTextTableForm } from '../components/tables/job-bank-theme-text-table/use-job-bank-theme-text-table';
import { createUrlQuery } from '../helpers/create-url-query';
import { JobBankAskAddText } from '../pages/main/job-bank-ask-add-text-page/use-job-bank-ask-add-text-page';
import { AskAnswersList } from '../pages/main/job-bank-ask-view-page/elem/auxiliary-tables/answers/use-answers-table';
import { MultiTaskQuestion } from '../pages/main/job-bank-multiple-add-question-page/use-job-bank-multiple-add-question-page';
import { QuestionCreateForm } from '../pages/main/job-bank-multiple-create-question-page/use-job-bank-multiple-create-question-page';
import { MultiTaskListTableForm } from '../pages/main/job-bank-multiple-page/elem/questions-table/use-questions-table';
import { TasksGroupNumberTableForm } from '../pages/main/job-bank-task-page/elem/tasks/use-tasks-table';
import { TextCreateForm } from '../pages/main/job-bank-text-create-page/use-job-bank-text-create';
import { TextViewAddedQuestionTableForm } from '../pages/main/job-bank-text-view-page/elem/use-add-questions-table';
import { ApiFetchCommonParams, ApiPaginationResponse, ApiPostProps, ApiResponse } from '../types/api';
import { TableFetchCommonParams } from '../types/api/table';
import { ExamProps } from '../types/exams';
import {
  AskViewText,
  FetchJobBankAskTagsList,
  FetchJobBankThemeCreateProps,
  FetchJobBankThemeGroupCreateProps,
  FetchJobBankThemeGroupUpdateProps,
  FetchJobBankThemeUpdateProps,
  FetchTextForSeo,
  JobBankAskAnswersList,
  JobBankAskList,
  JobBankAskViewList,
  JobBankErrorMessage,
  JobBankMultiTaskList,
  JobBankTaskGroupView,
  JobBankTextView,
  JobBankThemeList,
  JobBankThemeText,
  MultiTaskQuestionList,
  MultiTaskQuestions,
  TasksGroupNumber,
  TextViewAddedQuestion,
  TextViewQuestion,
} from '../types/job-bank';
import { apiRequest } from './index';

export const fetchJobBankThemeList = async (
  props: TableFetchCommonParams<JobBankThemeTableForm>
): Promise<ApiPaginationResponse<JobBankThemeList[]>> =>
  apiRequest({
    url: `/job-bank-theme/index${createUrlQuery(props)}`,
  });

export const fetchJobBankErrorMessages = async (
  props: TableFetchCommonParams<JobBankErrorTableForm>
): Promise<ApiPaginationResponse<JobBankErrorMessage[]>> =>
  apiRequest({
    url: `/job-bank-ask-error/index${createUrlQuery(props)}`,
  });

export const fetchJobBankTaskGroupList = async (
  props: TableFetchCommonParams<JobBankGroupTableForm>
): Promise<ApiPaginationResponse<JobBankThemeList[]>> =>
  apiRequest({
    url: `/task-group/index${createUrlQuery(props)}`,
  });

export const fetchJobBankMultipleList = async (
  props: TableFetchCommonParams<JobBankMultipleForm>
): Promise<ApiPaginationResponse<JobBankMultiTaskList[]>> =>
  apiRequest({
    url: `/job-bank-ask-multiple/index${createUrlQuery(props)}`,
  });

export const fetchMultiTaskQuestionList = async (
  props: TableFetchCommonParams<MultiTaskListTableForm> & { id: string | undefined }
): Promise<ApiPaginationResponse<MultiTaskQuestionList[]>> =>
  apiRequest({
    url: `/job-bank-ask-multiple-number/list-question${createUrlQuery(props)}`,
  });

export const fetchAskGroupView = async (props: ApiFetchCommonParams): Promise<ApiResponse<JobBankTaskGroupView>> =>
  apiRequest({
    url: `/task-group/view${createUrlQuery(props)}`,
  });

export const fetchMultiTaskView = async (props: ApiFetchCommonParams): Promise<ApiResponse<JobBankMultiTaskList>> =>
  apiRequest({
    url: `/job-bank-ask-multiple/view${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeTextList = async (
  props: TableFetchCommonParams<JobBankThemeTextTableForm>
): Promise<ApiPaginationResponse<JobBankThemeText[]>> =>
  apiRequest({
    url: `/job-bank-theme-text/index${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeTextView = async (
  props: ApiFetchCommonParams
): Promise<ApiResponse<{ model: JobBankTextView }>> =>
  apiRequest({
    url: `/job-bank-text/view${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeTextViewList = async (
  props: TableFetchCommonParams<JobBankThemeTextViewList> & { theme_id: string | undefined }
): Promise<ApiPaginationResponse<JobBankTextView[]>> =>
  apiRequest({
    url: `/job-bank-text/index${createUrlQuery(props)}`,
  });

export const fetchThemeTextInfo = async (props: ApiFetchCommonParams): Promise<ApiResponse<JobBankThemeText>> =>
  apiRequest({
    url: `/job-bank-theme-text/view${createUrlQuery(props)}`,
  });

export const fetchTextCreate = async ({
  data,
  ...props
}: ApiPostProps<TextCreateForm> & { id: string | undefined }): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    method: 'post',
    url: `/job-bank-text/create${createUrlQuery(props)}`,
  });

export const fetchTextCreateAudio = async ({
  data,
  ...props
}: ApiPostProps<FormData> & { id: string | undefined }): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    method: 'post',
    url: `/job-bank-text/create-audio${createUrlQuery(props)}`,
  });

export const fetchTextUpdate = async ({
  data,
  ...props
}: ApiPostProps<FormData> & { id: string | undefined }): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    method: 'post',
    url: `/job-bank-text/update${createUrlQuery(props)}`,
  });

export const fetchDeleteText = async (props: { id: string | undefined | number }): Promise<ApiResponse<string>> =>
  apiRequest({
    url: `/job-bank-text/delete${createUrlQuery(props)}`,
  });

export const fetchJobBankAskList = async (
  props: TableFetchCommonParams<JobBankAskTableForm> & { theme: string | undefined }
): Promise<ApiPaginationResponse<JobBankAskList[]>> =>
  apiRequest({
    url: `/job-bank-ask/index${createUrlQuery(props)}`,
  });

export const fetchDeleteAsk = async (props: ApiFetchCommonParams): Promise<ApiResponse<string>> =>
  apiRequest({
    url: `/job-bank-ask/delete${createUrlQuery(props)}`,
  });

export const fetchTasksGroupNumber = async (
  props: TableFetchCommonParams<TasksGroupNumberTableForm> & { taskGroup: string | undefined }
): Promise<ApiPaginationResponse<TasksGroupNumber[]>> =>
  apiRequest({
    url: `/task-group-number/index${createUrlQuery(props)}`,
  });

export const fetchTextAddedQuestions = async (
  props: TableFetchCommonParams<TextViewAddedQuestionTableForm> & { jobBankText: string | undefined }
): Promise<ApiPaginationResponse<TextViewQuestion[]>> =>
  apiRequest({
    url: `/job-bank-text/add-question${createUrlQuery(props)}`,
  });

export const fetchViewTextAddedQuestions = async (
  props: TableFetchCommonParams<TextViewAddedQuestionTableForm> & { job_bank_text_id: string | undefined }
): Promise<ApiPaginationResponse<TextViewQuestion[]>> =>
  apiRequest({
    url: `/job-bank-ask-text/index${createUrlQuery(props)}`,
  });

export const fetchMultiTaskAddedQuestions = async (
  props: TableFetchCommonParams<MultiTaskQuestion> & { id: string | undefined }
): Promise<ApiPaginationResponse<MultiTaskQuestions[]>> =>
  apiRequest({
    url: `/job-bank-ask/question-job-bank-ask${createUrlQuery(props)}`,
  });

export const fetchViewTextAddedText = async (
  props: TableFetchCommonParams<TextViewAddedQuestionTableForm> & { job_bank_ask_id: string | undefined }
): Promise<ApiPaginationResponse<TextViewAddedQuestion[]>> =>
  apiRequest({
    url: `/job-bank-ask-text/index${createUrlQuery(props)}`,
  });

export const fetchAskAddText = async (
  props: TableFetchCommonParams<JobBankAskAddText> & { jobBankAsk: string | undefined }
): Promise<ApiPaginationResponse<AskViewText[]>> =>
  apiRequest({
    url: `/job-bank-ask/add-text${createUrlQuery(props)}`,
  });

export const fetchDeleteAskQuestions = async (props: {
  ask_id: number;
  id: string | undefined;
}): Promise<ApiResponse<string>> =>
  apiRequest({
    url: `/job-bank-text/remove-question-ask${createUrlQuery(props)}`,
  });

export const fetchDeleteMultiTaskQuestions = async (props: {
  // ask_id: number;
  id: number | undefined;
}): Promise<ApiResponse<string>> =>
  apiRequest({
    method: 'post',
    url: `job-bank-ask-multiple/remove-question${createUrlQuery(props)}`,
  });

export const fetchAskDeleteText = async (props: {
  id: string | undefined;
  text_id: number | undefined;
}): Promise<ApiResponse<string>> =>
  apiRequest({
    url: `/job-bank-ask/remove-text-ask${createUrlQuery(props)}`,
  });

export const fetchAddAskQuestions = async (props: {
  ask_id: number;
  id: string | undefined;
}): Promise<ApiResponse<string>> =>
  apiRequest({
    url: `/job-bank-text/add-question-ask${createUrlQuery(props)}`,
  });

export const fetchAddMultiTaskQuestions = async ({
  data,
  ...props
}: ApiPostProps<{ number: number }> & {
  id: number | undefined;
  multiple_id: number;
}): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    errorToast: { show: false },
    method: 'post',
    url: `/job-bank-ask-multiple/add-question${createUrlQuery(props)}`,
  });

export const fetchAddAskText = async (props: {
  id: string | undefined;
  text_id: number;
}): Promise<ApiResponse<string>> =>
  apiRequest({
    url: `/job-bank-ask/add-text-ask${createUrlQuery(props)}`,
  });

export const fetchJobBankAskView = async (props: ApiFetchCommonParams): Promise<ApiResponse<JobBankAskViewList>> =>
  apiRequest({
    url: `/job-bank-ask/view${createUrlQuery(props)}`,
  });

export const fetchAskAnswers = async (
  props: TableFetchCommonParams<AskAnswersList> & { job_bank_ask_id: string | undefined }
): Promise<ApiPaginationResponse<JobBankAskAnswersList[]>> =>
  apiRequest({
    url: `/job-bank-ask-answer/index${createUrlQuery(props)}`,
  });

export const fetchJobBankAskCreate = async ({
  data,
  ...props
}: ApiPostProps<FormData> & { id: string | undefined }): Promise<ApiResponse<number>> =>
  apiRequest({
    data,
    errorToast: { show: false },
    method: 'post',
    url: `/job-bank-ask/create${createUrlQuery(props)}`,
  });

export const fetchMultiTaskQuestionCreate = async ({
  data,
  ...props
}: ApiPostProps<QuestionCreateForm> & { id: string | undefined }): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    errorToast: { show: false },
    method: 'post',
    url: `job-bank-ask-multiple/create-question${createUrlQuery(props)}`,
  });

export const fetchJobBankAskUpdate = async ({
  data,
  ...props
}: ApiPostProps<FormData> & { id: string | undefined }): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    errorToast: { show: false },
    method: 'post',
    url: `/job-bank-ask/update${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeView = async (
  props: ApiFetchCommonParams & { id: string | undefined }
): Promise<ApiResponse<JobBankThemeList>> =>
  apiRequest({
    url: `/job-bank-theme/view${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeDelete = async (props: ApiFetchCommonParams): Promise<ApiResponse<string>> =>
  apiRequest({
    url: `/job-bank-theme/delete${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeTextDelete = async (props: ApiFetchCommonParams): Promise<ApiResponse<string>> =>
  apiRequest({
    url: `/job-bank-theme-text/delete${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeCreate = async ({
  data,
  ...props
}: FetchJobBankThemeCreateProps): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    method: 'post',
    url: `/job-bank-theme/create${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeUpdate = async ({
  data,
  ...props
}: FetchJobBankThemeUpdateProps): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    method: 'post',
    url: `/job-bank-theme/update${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeGroupCreate = async ({
  data,
  ...props
}: FetchJobBankThemeGroupCreateProps): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    method: 'post',
    url: `/task-group/create${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeGroupUpdate = async ({
  data,
  ...props
}: FetchJobBankThemeGroupUpdateProps): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    method: 'post',
    url: `/task-group/update${createUrlQuery(props)}`,
  });

export const fetchJobBankMultiTaskUpdate = async ({
  data,
  ...props
}: FetchJobBankThemeGroupUpdateProps): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    method: 'post',
    url: `/job-bank-ask-multiple/update${createUrlQuery(props)}`,
  });

export const fetchJobBankMultiTaskCreate = async ({
  data,
  ...props
}: ApiPostProps<JobBankDailogMultiTaskForm>): Promise<ApiResponse<number>> =>
  apiRequest({
    data,
    method: 'post',
    url: `job-bank-ask-multiple/create${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeTextCreate = async ({
  data,
  ...props
}: ApiPostProps<JobBankForm>): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    errorToast: { show: false },
    method: 'post',
    url: `/job-bank-theme-text/create${createUrlQuery(props)}`,
  });

export const fetchJobBankThemeTextUpdate = async ({
  data,
  ...props
}: FetchJobBankThemeGroupUpdateProps): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    errorToast: { show: false },
    method: 'post',
    url: `/job-bank-theme-text/update${createUrlQuery(props)}`,
  });

export const fetchJobBankDeleteTask = async (props: ApiFetchCommonParams): Promise<ApiResponse<string>> =>
  apiRequest({ url: `/task-group/delete${createUrlQuery(props)}` });

export const fetchJobBankDeleteMultiTask = async (props: ApiFetchCommonParams): Promise<ApiResponse<string>> =>
  apiRequest({ url: `/job-bank-ask-multiple/delete${createUrlQuery(props)}` });

export const fetchJobBankDeleteTaskNumber = async (props: ApiFetchCommonParams): Promise<ApiResponse<string>> =>
  apiRequest({ url: `/task-group/delete-task-number${createUrlQuery(props)}` });

export const fetchJobBankTasksCount = async (): Promise<ApiResponse<number>> =>
  apiRequest({
    url: '/job-bank-theme/count-tasks',
  });

export const fetchJobBankTaskNumberCreate = async ({
  data,
  ...props
}: ApiPostProps<{ task_number: number }> & { id: string | undefined }): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    errorToast: { show: false },
    method: 'post',
    url: `/task-group/add-task${createUrlQuery(props)}`,
  });

export const fetchJobBankAskCreateFile = async ({
  data,
  ...props
}: {
  data: FormData;
  id: string | undefined;
}): Promise<ApiResponse<string>> =>
  apiRequest({
    data,
    method: 'post',
    errorToast: { show: false },
    url: `/documents/create-file-job-bank-ask${createUrlQuery(props)}`,
  });

export const fetchAskTagsList = async ({
  limit = 3000,
  subject,
}: ApiFetchCommonParams & { subject: number | undefined; limit?: number }): Promise<
  ApiPaginationResponse<FetchJobBankAskTagsList[]>
> =>
  apiRequest({
    url: `/tag/index-question?limit=${limit}&subject=${subject}`,
  });

export const fetchAskViewTagsList = async ({
  id,
}: ApiFetchCommonParams): Promise<ApiResponse<FetchJobBankAskTagsList[]>> =>
  apiRequest({
    url: `/job-bank-ask/get-tags?id=${id}`,
  });

export const fetchTagAskCreate = async ({
  data,
  ...props
}: {
  data: { id: string | undefined; tags: { tags: number[] } };
  id: string | undefined;
}): Promise<ApiResponse<string>> =>
  apiRequest({
    data: { tags: data.tags.tags, id: data.id },
    method: 'post',
    errorToast: { show: false },
    url: `/job-bank-ask/update-tag${createUrlQuery(props)}`,
  });

export const fetchTeacherExamList = async (): Promise<ApiResponse<ExamProps[]>> =>
  apiRequest({
    url: '/course/get-exam-role',
  });

export const fetchTextForSeo = async (params: FetchTextForSeo['req']): FetchTextForSeo['res'] =>
  apiRequest({
    url: '/job-bank-theme/seo-text',
    params,
  });
