import { Suspense } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { Fallback } from '../../components/fallback/fallback';
import { AUTH_ROUTES } from '../../constants/routes';

export const AuthStack = () => (
  <Suspense fallback={<Fallback />}>
    <Routes>
      {AUTH_ROUTES.map(({ path, component }) => (
        <Route key={path} element={component} path={path} />
      ))}

      <Route element={<Navigate replace to='/login' />} path='*' />
    </Routes>
  </Suspense>
);
