import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { fetchTextCreateAudio, fetchThemeTextInfo } from '../../../api/job-bank';
import { apiErrorParse } from '../../../helpers/api-error-parse';
import { formDataParse } from '../../../helpers/form-data-parse';
import { showAlert } from '../../../helpers/show-toast';
import { useBreadcrumbs } from '../../../hooks/use-breadcrumbs';
import { queryClient } from '../../../index';
import { ApiValidationError } from '../../../types/api';

export interface TextCreateAudioForm {
  file: string;
  name: string;
}

export const useJobBankTextCreateAudioPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const textInfoQuery = useQuery(['job-bank-theme-text-info', id], () => fetchThemeTextInfo({ id }));

  const url = useBreadcrumbs('/job-bank', 'job-bank-theme-text-list');
  const textViewUrl = useBreadcrumbs(`/job-bank-text/${textInfoQuery.data?.data.id}`, 'job-bank-theme-text-view-list');

  const breadcrumbs = [
    { path: url, title: 'Банк заданий' },
    {
      path: textViewUrl,
      title: `${textInfoQuery.data?.data.subjectText}(${textInfoQuery.data?.data.examText})`,
    },
    { title: 'Создать аудио' },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const formMethods = useForm<TextCreateAudioForm>();

  const createAudio = async (formData: TextCreateAudioForm) => {
    setIsLoading(true);

    const postData = formDataParse({ data: formData });

    const { error, status, data } = await fetchTextCreateAudio({ data: postData, id });

    if (status === 406) {
      apiErrorParse<TextCreateAudioForm>({
        error: data as unknown as ApiValidationError,
        setError: formMethods.setError,
      });
      setIsLoading(false);

      return;
    }

    if (!error) {
      await queryClient.refetchQueries(['job-bank-theme-text-view-list']);
      showAlert({ message: data, type: 'success' });
      setIsLoading(false);
      navigate(-1);
    }
  };

  return { formMethods, isLoading, createText: createAudio, breadcrumbs, textInfoQuery };
};
