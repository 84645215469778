import { Card } from 'antd';
import { FormProvider } from 'react-hook-form';

import { CustomButton } from '../../../components/custom-button/custom-button';
import { CustomInput } from '../../../components/form-elements/custom-input/custom-input';
import { FileUpload } from '../../../components/form-elements/file-upload/file-upload';
import { PageHeader } from '../../../components/page-header/page-header';
import { Spinner } from '../../../components/spinner/spinner';
import { useJobBankTextCreateAudioPage } from './use-job-bank-text-create-audio-page';

export const JobBankTextCreateAudioPage = () => {
  const { formMethods, createText, breadcrumbs, isLoading, textInfoQuery } = useJobBankTextCreateAudioPage();

  document.title = 'Создать аудио';

  if (textInfoQuery.isLoading) return <Spinner />;

  return (
    <>
      <PageHeader breadcrumbs={breadcrumbs}>Создать аудио</PageHeader>

      <Card>
        <FormProvider {...formMethods}>
          <form>
            <div className='space-y-4'>
              <CustomInput
                label='Название'
                maxLength={140}
                name='name'
                placeholder='Введите название'
                rules={{ required: 'Обязательное поле' }}
              />

              <FileUpload
                accept={false}
                extensionList='MP3, WEBM'
                label='Аудио'
                name='file'
                rules={{ required: 'Обязательное поле' }}
              />

              <CustomButton loading={isLoading} onClick={formMethods.handleSubmit(createText)}>
                Сохранить
              </CustomButton>
            </div>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};
