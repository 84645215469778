import { ButtonSize, ButtonView } from '../components/custom-button/custom-button';

export const BUTTON_SIZES: Record<ButtonSize, HTMLElement['className']> = {
  m: 'text-base px-7 min-h-[56px]',
  s: 'text-sm px-5 min-h-[36px]',
  xs: 'text-xs px-3.5 min-h-[28px]',
};

export const BUTTON_VIEWS: Record<ButtonView, HTMLElement['className']> = {
  danger: 'bg-gradient-to-r from-red to-pink/95',
  default: 'border-border border !text-dark hover:!opacity-100 hover:border-primary hover:!text-primary',
  primary: 'bg-gradient-to-r from-primary to-[#7C4894]/80',
  success: 'bg-gradient-to-r from-green to-yellow/90',
  login: 'bg-gradient-to-r from-green to-yellow/75',
  logout: 'bg-gradient-to-r from-red to-green/50',
  light:
    '!text-dark-gray bg-light-gray !rounded-xl !shadow-none hover:!shadow-none hover:bg-gradient-to-r hover:from-primary hover:to-[#7C4894]/80 hover:!text-white',
};
