import { createRef, RefObject, useEffect, useRef } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useStore } from 'effector-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOutsideClick } from 'rooks';

import { fetchNotifications } from '../../api/message';
import { fetchCheckNotifications } from '../../api/user';
import { logout } from '../../store/auth';
import { $notificationCount, $notificationCountApi } from '../../store/notification';
import { $profile } from '../../store/profile';

const DROPDOWN_CLASSNAME =
  'invisible absolute top-full right-0 z-[2100] translate-y-3 divide-y-[1px] divide-border rounded border border-border bg-white text-sm opacity-0 shadow-2xl transition-all';

export const useAppHeader = () => {
  const profile = useStore($profile);
  const notificationCount = useStore($notificationCount);

  const location = useLocation();
  const navigate = useNavigate();
  const lastNotificationsQueryData = useQuery(['notification-last-list', location.pathname], () =>
    fetchNotifications()
  );

  const notificationListRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);
  const dropdownListRef = useRef<RefObject<HTMLDivElement>[]>([createRef(), createRef()]);

  const checkNotifications = async () => {
    if (!dropdownListRef.current[0].current?.className.includes('invisible')) {
      $notificationCountApi.openMenu();
      await fetchCheckNotifications();
    }
  };

  const hideDropdown = (index: number) => {
    dropdownListRef.current[index].current?.classList.add('translate-y-3', 'opacity-0', 'invisible');
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleDropdownToggle = (index: number) => {
    dropdownListRef.current[index].current?.classList.toggle('translate-y-3');
    dropdownListRef.current[index].current?.classList.toggle('opacity-0');
    dropdownListRef.current[index].current?.classList.toggle('invisible');
  };

  useOutsideClick(notificationListRef, () => hideDropdown(0));
  useOutsideClick(profileRef, () => hideDropdown(1));

  useEffect(() => {
    hideDropdown(0);
    hideDropdown(1);
  }, [location.pathname]);

  useEffect(() => {
    if (lastNotificationsQueryData?.data?.data.count) {
      $notificationCountApi.set(lastNotificationsQueryData?.data.data.count || 0);
    }
  }, [lastNotificationsQueryData?.data?.data?.count]);

  return {
    DROPDOWN_CLASSNAME,
    lastNotificationsQueryData,
    dropdownListRef,
    handleDropdownToggle,
    checkNotifications,
    notificationListRef,
    notificationCount,
    handleLogout,
    profile,
    location,
    profileRef,
  };
};
