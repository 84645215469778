import { HTMLProps } from 'react';

import cn from 'classnames';

import { CustomIcon } from '../custom-icon/custom-icon';
interface SpinnerProps {
  className?: HTMLProps<HTMLDivElement>['className'];
}

export const Spinner = ({ className }: SpinnerProps) => (
  <div className={cn('flex h-[calc(100vh-56)] flex-1 items-center justify-center', className)}>
    <CustomIcon className='h-6 w-6 animate-spin' name='spinner' />
  </div>
);
