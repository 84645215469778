import { PropsWithChildren } from 'react';

import { usePermission } from '../../hooks/use-permission';

interface PermissionWrapProps {
  grants: [string, string];
}

export const PermissionWrap = ({ children, grants }: PropsWithChildren<PermissionWrapProps>) => {
  const hasPermission = usePermission(grants);

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
};
