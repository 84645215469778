import { Suspense } from 'react';

import cn from 'classnames';
import { Navigate, Route, Routes } from 'react-router-dom';

import { CustomAnchor } from '../../components/anchor/custom-anchor';
import { AppHeader } from '../../components/app-header/app-header';
import { Fallback } from '../../components/fallback/fallback';
import { MainMenu } from '../../components/main-menu/main-menu';
import { Spinner } from '../../components/spinner/spinner';
import { MAIN_ROUTES } from '../../constants/routes';
import { useMainStack } from './use-main-stack';

import style from './main-stack.module.css';

export const MainStack = () => {
  const { contentRef, menuRef, toggleMenu, queriesData } = useMainStack();

  if (queriesData.some((q) => q.isLoading)) {
    return <Spinner />;
  }

  return (
    <>
      <MainMenu ref={menuRef} />

      <div ref={contentRef} className={cn('flex w-full flex-col xl:pl-64', style.wrap)}>
        <AppHeader toggleMenu={toggleMenu} />

        <div className='relative flex flex-1 flex-col space-y-6 p-4 xl:p-6'>
          <Suspense fallback={<Fallback />}>
            <Routes>
              {MAIN_ROUTES.map(({ path, component }) => (
                <Route key={path} element={component} path={path} />
              ))}

              <Route element={<Navigate replace to='/' />} path='*' />
            </Routes>
          </Suspense>
        </div>
      </div>

      <CustomAnchor />
    </>
  );
};
