export const useBreadcrumbs = (baseUrl: string, tableName?: string) => {
  const ls = JSON.parse(window.sessionStorage.getItem('breadcrumbs') || '{}');
  const breadcrumbsTable = window.sessionStorage.getItem('breadcrumbsTable');

  return ls[tableName || breadcrumbsTable!] ? `${baseUrl}${ls[tableName || breadcrumbsTable!]}` : baseUrl;
};

export const setBreadcrumbsUrl = (tableName: string, sStorageTableName?: boolean) => {
  const ls = window.sessionStorage.getItem('breadcrumbs');
  const prev = JSON.parse(ls || '{}');

  const value = { ...prev, [tableName]: decodeURIComponent(window.location.search) };

  window.sessionStorage.setItem('breadcrumbs', JSON.stringify(value));
  if (sStorageTableName) {
    window.sessionStorage.setItem('breadcrumbsTable', tableName);
  } else {
    window.sessionStorage.removeItem('breadcrumbsTable');
  }
};
