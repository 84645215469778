import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form';

import { ApiValidationError } from '../types/api';

interface ApiErrorParseProps<T extends FieldValues> {
  error: ApiValidationError;
  setError: UseFormSetError<T>;
  name?: string;
}

export const apiErrorParse = <T extends FieldValues>({ setError, error, name }: ApiErrorParseProps<T>) => {
  if (typeof error === 'string') {
    setError(name as FieldPath<T>, {
      message: error,
    });
  }

  for (const [k, v] of Object.entries(error)) {
    setError(k as FieldPath<T>, {
      message: v[0],
    });
  }
};

export const apiErrorParseHW = <T extends FieldValues>({ setError, error }: ApiErrorParseProps<T>) => {
  for (const [k, v] of Object.entries(error)) {
    setError(k.replace('scopes', '') as FieldPath<T>, {
      message: v[0],
    });
  }
};
