import { forwardRef, SVGProps } from 'react';

import cn from 'classnames';

import svgSprite from '../../static/images/icons-sprite.svg';

export interface IconProps extends SVGProps<SVGSVGElement> {
  name: string;
}

export const CustomIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ name, className, width = 16, height = 16, ...props }, parentRef) => (
    <svg {...props} ref={parentRef} className={cn('fill-current', className)} height={height} width={width}>
      <use href={`${svgSprite}#${name}`} />
    </svg>
  )
);

CustomIcon.displayName = 'CustomIcon';
