import { createApi, createStore } from 'effector';

import { SearchUserListRes } from '../types/user-info/user';

export const $userSearchList = createStore<SearchUserListRes[]>([]);
export const $courseSearchList = createStore<SearchUserListRes[]>([]);
export const $themeSearchList = createStore<SearchUserListRes[]>([]);
export const $userSearchListLoading = createStore<boolean>(false);

export const $userSearchListApi = createApi($userSearchList, {
  reset: () => [],
  set: (_, i: SearchUserListRes[]) => i,
});

export const $themeSearchListApi = createApi($themeSearchList, {
  reset: () => [],
  set: (_, i: SearchUserListRes[]) => i,
});

export const $courseSearchListApi = createApi($courseSearchList, {
  reset: () => [],
  set: (_, i: SearchUserListRes[]) => i,
});

export const $userSearchListLoadingApi = createApi($userSearchListLoading, {
  on: () => true,
  off: () => false,
});
