/* eslint-disable @typescript-eslint/ban-ts-comment */
interface FormDataParseProps<T> {
  data: T;
  fileName?: string;
  files?: File[];
}

export const formDataParse = <T>({ data, files = [], fileName }: FormDataParseProps<T>) => {
  const postData = new FormData();

  if (fileName) {
    // @ts-ignore
    delete data[fileName];
  }

  for (const [k, v] of Object.entries(data as Record<string, unknown>)) {
    postData.append(k, v as string);
  }

  if (files?.length) {
    for (const file of files) {
      postData.append(`${fileName}[]`, file);
    }
  }

  return postData;
};
