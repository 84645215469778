import { useEffect } from 'react';

import { useStore } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import { $profile } from '../store/profile';

interface RedirectProps {
  link: string;
  role: string;
}

export const useRedirect = ({ role, link }: RedirectProps) => {
  const profile = useStore($profile);
  const navigate = useNavigate();

  useEffect(() => {
    const isRedirect = window.sessionStorage.getItem('redirect');

    if (!isRedirect && profile?.roles?.length === 1 && !!profile?.roles.find((el) => el.role === role)) {
      navigate(link);
      window.sessionStorage.setItem('redirect', 'true');
    }
  }, [link, navigate, profile?.roles, role]);
};
