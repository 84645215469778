import { useEffect, useRef } from 'react';

import cn from 'classnames';

import { CustomIcon } from '../custom-icon/custom-icon';

export const CustomAnchor = () => {
  const anchorRef = useRef<HTMLDivElement>(null);

  const toTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 400 && anchorRef.current
        ? anchorRef.current.classList.remove('scale-0')
        : anchorRef.current?.classList.add('scale-0');
    });
  }, []);

  return (
    <div
      ref={anchorRef}
      className={cn('fixed right-6 bottom-16 z-50 scale-0 rounded-full bg-black/10 p-3 transition m595:!hidden')}
      onClick={toTop}
    >
      <CustomIcon name='sr-angle-top' />
    </div>
  );
};
