export const createUrlQuery = ({ askSymbol, ...props }: any) => {
  const url = new URLSearchParams(getEntries(props)).toString();
  const endString = askSymbol ? '?' : '';

  return url.length ? `?${url}` : endString;
};

const getEntries = (o = {}) => {
  const entries: string[][] = [];

  for (const [k, v] of Object.entries(o)) {
    if (v) {
      if (Array.isArray(v)) entries.push(...v.flatMap(getEntries));
      else if (typeof v === 'object') entries.push(...getEntries(v as string[]));
      else entries.push([k, v as string]);
    }
  }

  return entries;
};
