import { ButtonHTMLAttributes, PropsWithChildren } from 'react';

import cn from 'classnames';

import { BUTTON_SIZES, BUTTON_VIEWS } from '../../constants/button';
import { CustomIcon } from '../custom-icon/custom-icon';
import { PermissionWrap } from '../permission-wrap/permission-wrap';

export type ButtonView = 'danger' | 'primary' | 'default' | 'success' | 'login' | 'logout' | 'light';

export type ButtonSize = 'xs' | 's' | 'm';

export interface CustomButtonProps extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  grants?: [string, string];
  loading?: boolean;
  size?: ButtonSize;
  view?: ButtonView;
}

export const CustomButton = ({
  children,
  className,
  view = 'primary',
  size = 's',
  disabled,
  loading = false,
  grants,
  ...props
}: CustomButtonProps) => {
  const button = (
    <button
      className={cn(
        `inline-flex cursor-pointer items-center justify-center rounded-lg py-1.5 text-white shadow-lg transition hover:opacity-90 ${BUTTON_SIZES[size]} ${BUTTON_VIEWS[view]}`,
        { '!opacity-70': disabled },
        { '!pointer-events-none !cursor-not-allowed !select-none': disabled || loading },
        className
      )}
      {...props}
    >
      {loading ? (
        <>
          <CustomIcon className={cn('h-4 w-4 animate-spin', { 'mr-2': loading && children })} name='spinner' />
          {children}
        </>
      ) : (
        children
      )}
    </button>
  );

  if (grants) {
    return <PermissionWrap grants={grants}>{button}</PermissionWrap>;
  }

  return button;
};
