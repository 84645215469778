import { forwardRef } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import { LEFT_MENU } from '../../constants/routes';
import { useQueriesData } from '../../hooks/use-queries-data';
import { MainMenuNavLink } from './elems/main-menu-nav-link/main-menu-nav-link';

import logo from '../../static/images/logo-ad.png';

import style from './main-menu.module.css';

export const MainMenu = forwardRef<HTMLDivElement>((_, parentRef) => {
  const { accessListQueryData } = useQueriesData();

  return (
    <div
      ref={parentRef}
      className='custom-scrollbar-menu max-m595:pl fixed top-0 left-0 bottom-0 flex w-64 -translate-x-64 flex-col space-y-1 overflow-y-auto bg-dark px-2.5 pb-3 text-sm text-white max-m595:w-24 xl:translate-x-0'
    >
      <div>
        <Link className={cn('flex justify-center py-3 px-6 max-m595:py-2 max-m595:px-2', style.logo)} to='/'>
          <img
            alt='logo'
            className='w-20 object-contain max-m595:ml-0 max-m595:w-screen max-m595:items-start'
            src={logo}
          />
        </Link>
      </div>

      <div className='max-m595:justify-center'>
        {LEFT_MENU?.map(
          (i) =>
            i.roleAccess &&
            accessListQueryData?.[i.roleAccess?.[0]]?.[i.roleAccess?.[1]] && <MainMenuNavLink key={i.path} {...i} />
        )}
      </div>
    </div>
  );
});

MainMenu.displayName = 'MainMenu';
