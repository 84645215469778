import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import { App } from './app';
import { QUERY_REFETCH_OPTIONS } from './constants/query-refetch-options';

import './static/css/index.css';

import 'dayjs/locale/ru';

dayjs.locale('ru-RU');

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: Number.POSITIVE_INFINITY,
      // refetchOnMount: false,
      // staleTime: Number.POSITIVE_INFINITY,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 0,
      ...QUERY_REFETCH_OPTIONS,
    },
  },
});

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ConfigProvider locale={ruRU}>
        <App />
      </ConfigProvider>
    </BrowserRouter>

    <Toaster position='top-right' />

    {/*{process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}*/}
  </QueryClientProvider>
);
