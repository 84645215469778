import cn from 'classnames';

import { CustomIcon } from '../custom-icon/custom-icon';

interface AvatarIconProps {
  className?: string;
  iconClassName?: string;
}

export const AvatarIcon = ({ className, iconClassName }: AvatarIconProps) => (
  <span className={cn('flex min-h-[2.5rem] min-w-[2.5rem] items-center justify-center rounded-full bg-bg', className)}>
    <CustomIcon className={cn('h-4 w-4 text-black/50', iconClassName)} name='sr-user' />
  </span>
);
