import { HTMLProps, PropsWithChildren } from 'react';

import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

export const InputLabel = ({ children, name, className, ...props }: PropsWithChildren<HTMLProps<HTMLLabelElement>>) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = name && (errors[name]?.message as string);

  if (!children) return null;

  return (
    <label
      className={cn('flex cursor-pointer items-center gap-1.5 text-sm text-[#6d7885]', className, {
        ' !text-red': !!error,
      })}
      {...props}
    >
      {children}
    </label>
  );
};
