import { useEffect, useRef } from 'react';

import { useQueries, useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { fetchBaseUrl } from '../../api/desktop';
import { fetchUserBlockedList, fetchUserCompletedLessons } from '../../api/profile';
import {
  fetchCourseType,
  fetchRoles,
  fetchSetOnline,
  fetchUserAccess,
  fetchUserCourseSubjectList,
  fetchUserExam,
  fetchUserInactiveCourse,
  fetchUserStatus,
  fetchUserTags,
} from '../../api/user';
import { useRedirect } from '../../hooks/use-redirect';

import style from '../../components/main-menu/main-menu.module.css';
import wrapStyle from './main-stack.module.css';

export const useMainStack = () => {
  const location = useLocation();

  useRedirect({ role: 'manager', link: '/invoice' });
  const contentRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useQuery(['online', location.pathname], fetchSetOnline, {
    cacheTime: 0,
    staleTime: 0,
  });

  const queriesData = useQueries({
    queries: [
      { queryFn: fetchUserAccess, queryKey: ['accessList'] },
      { queryFn: fetchRoles, queryKey: ['roleList'] },
      { queryFn: fetchUserTags, queryKey: ['tagList'] },
      { queryFn: fetchUserStatus, queryKey: ['statusList'] },
      { queryFn: fetchUserInactiveCourse, queryKey: ['inactiveCourseList'] },
      { queryFn: fetchUserCourseSubjectList, queryKey: ['subjectList'] },
      { queryFn: fetchUserExam, queryKey: ['examTypeList'] },
      { queryFn: fetchCourseType, queryKey: ['courseTypeList'] },
      { queryFn: fetchUserBlockedList, queryKey: ['blockedList'] },
      { queryFn: fetchUserCompletedLessons, queryKey: ['completedLesson'] },
      { queryFn: fetchBaseUrl, queryKey: ['base-url'] },
    ],
  });

  const toggleMenu = () => {
    menuRef.current?.classList.toggle(style.collapsedMenu);
    contentRef.current?.classList.toggle(wrapStyle.wrapIsOpened);
  };

  useEffect(() => {
    menuRef.current?.classList.remove(style.collapsedMenu);
    contentRef.current?.classList.remove(wrapStyle.wrapIsOpened);
  }, [location.pathname]);

  return { contentRef, menuRef, queriesData, toggleMenu };
};
